export enum DataTypeState {
  RELEASED = 'RELEASED',
  CANDIDATE = 'CANDIDATE',
  DEPRECATED = 'DEPRECATED',
  FROZEN = 'FROZEN',
  INVALIDATED = 'INVALIDATED',
}
export enum ValueType {
  STRING = 'STRING',
  DOUBLE = 'DOUBLE',
  DATE = 'DATE',
  ENUM = 'ENUM',
  SET = 'SET',
}
export type RecommendedSettings = {
  unitValue: string | null
  format: string | null
  range: number[] | null
  maxLength: number | null
  minLength: number | null
}
export type CrudType = {
  create: boolean
  read: boolean
  delete: boolean
}

export class DataType {
  public static createFromJson (json: any): DataType {
    return new DataType(
      json.id,
      json.version,
      json.locale,
      json.state,
      json.category,
      json.typeIdentifier,
      json.label,
      json.description ?? null,
      json.valueType,
      json.valueTypeEnum ? ValueTypeEnum.createFromJson(json.valueTypeEnum) : null,
      json.valueTypeSets ? json.valueTypeSets.map(DataTypeChild.createFromJson) : null,
      json.unitType ? UnitType.createFromJson(json.unitType) : null,
      json.recommendedSettings ?? null,
      json.approval ?? [],
      json.entrustApproval ?? null,
      json.officialToolPermission ?? null,
      json.customId ?? null,
      json.serviceGroup ?? [],
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.createdBy,
      json.updatedBy,
    )
  }

  public createSearchTypeIdentifier (): { typeIdentifier: string, customId?: string | null} {
    return {
      typeIdentifier: this.typeIdentifier,
      customId: this.customId,
    }
  }

  constructor (
    public id: string,
    public version: number,
    public locale: string,
    public state: DataTypeState,
    public category: string,
    public typeIdentifier: string,
    public label: string,
    public description: string | null,
    public valueType: ValueType,
    public valueTypeEnum: ValueTypeEnum | null,
    public valueTypeSets: DataTypeChild[] | null,
    public unitType: UnitType | null,
    public recommendedSettings: RecommendedSettings | null,
    public approval: CrudType[],
    public entrustApproval: CrudType | null,
    public officialToolPermission: CrudType,
    public customId: string | null,
    public serviceGroup: string[],
    public createdAt: Date,
    public updatedAt: Date,
    public createdBy: string,
    public updatedBy: string,
    ) {}
}

export class DataTypeChild {
  public static createFromJson (json: any): DataTypeChild {
    return new DataTypeChild(
      json.typeIdentifier,
      json.label,
      json.description ?? null,
      json.valueType,
      json.valueTypeEnum ? ValueTypeEnum.createFromJson(json.valueTypeEnum) : null,
      json.unitType ? UnitType.createFromJson(json.unitType) : null,  
      json.recommendedSettings ?? null,
    )
  }
  constructor (
    public typeIdentifier: string,
    public label: string,
    public description: string | null,
    public valueType: ValueType,
    public valueTypeEnum: ValueTypeEnum | null,
    public unitType: UnitType | null,
    public recommendedSettings: RecommendedSettings | null,
  ) {}
}

export class UnitType {
  public static createFromJson (json: any): UnitType {
    return new UnitType(
      json.id,
      json.version,
      json.locale,
      json.state,
      json.typeIdentifier,
      json.label,
      json.values,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.createdBy,
      json.updatedBy,
    )
  }

  constructor (
    public id: string,
    public version: number,
    public locale: string,
    public state: string,
    public typeIdentifier: string,
    public label: string,
    public values: {
      identifier: string
      label: string
      symbol: string
    }[],
    public createdAt: Date,
    public updatedAt: Date,
    public createdBy: string,
    public updatedBy: string,
  ) {}
}

export class ValueTypeEnum {
  public static createFromJson (json: any): ValueTypeEnum {
    return new ValueTypeEnum(
      json.id,
      json.version,
      json.locale,
      json.state,
      json.typeIdentifier,
      json.label,
      json.values,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.createdBy,
      json.updatedBy,
    )
  }

  constructor (
    public id: string,
    public version: number,
    public locale: string,
    public state: string,
    public typeIdentifier: string,
    public label: string,
    public values: {
      identifier: string
      label: string
      rawValue: number
    }[],
    public createdAt: Date,
    public updatedAt: Date,
    public createdBy: string,
    public updatedBy: string,
  ) {}
}
