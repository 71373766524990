import api from '@/api'
import { DataType } from '@/models/dataType'
import { fetchApprovals } from '@/services/approval.service'
import { fetchDataTypes } from '@/services/dataType.service'
import { User } from '@auth0/auth0-spa-js'
import { useAuth0 } from '@auth0/auth0-vue'
import { createStore } from 'vuex'

type State = {
  loading: boolean
  error: any
  token: string | null
  viewer: User | null
  version: string | null
  lastModified: Date | null
  dataTypes: DataType[]
  hasApprovals: boolean
}
export const TOKEN_KEY = 'UsPHR_TOKEN'

export enum SignInError {
  'NOT_FOUND' = 'NOT_FOUND',
  'DIFFERENT_ID' = 'DIFFERENT_ID',
}

export default createStore({
  state: {
    loading: false,
    error: null,
    token: localStorage.getItem(TOKEN_KEY) ?? null,
    viewer: null,
    version: null,
    lastModified: null,
    dataTypes: [],
    hasApprovals: false,
  },

  getters: {
    isAuthenticated: (state: State) => !!state.viewer,
    viewer: (state: State) => state.viewer,
    error: (state: State) => state.error,
    dataTypes: (state: State) => state.dataTypes,
    hasApprovals: (state: State) => state.hasApprovals,
  },

  mutations: {
    loading (state: State, value: boolean) {
      state.loading = value
    },

    error (state: State, value: any) {
      state.error = value
    },

    token (state: State, token: string | null) {
      state.token = token
    },

    viewer (state: State, viewer: User) {
      state.viewer = viewer
    },

    version (state: State, { version, lastModified }) {
      state.version = version
      state.lastModified = lastModified
    },

    dataTypes (state: State, dataTypes: DataType[]) {
      state.dataTypes = dataTypes
    },

    hasApprovals (state: State, hasApprovals: boolean) {
      state.hasApprovals = hasApprovals
    },
  },

  actions: {
    async signIn ({ dispatch, commit }: { dispatch: any; commit: any }, user: User): Promise<SignInError | null> {
      commit('loading', true)
      const { getAccessTokenSilently } = useAuth0()
      const token = await getAccessTokenSilently()
      if (token) {
        localStorage.setItem(TOKEN_KEY, token)
      } else {
        localStorage.removeItem(TOKEN_KEY)
      }
      commit('token', token)

      if (token) {
        commit('viewer', user)
        await dispatch('fetchDataTypes')
        await dispatch('fetchApprovals')
      }

      commit('loading', false)
      return null
    },

    async signOut ({ commit }: { commit: any }) {
      commit('token', null)
      commit('viewer', null)
    },

    async version ({ state, commit }: { state: any; commit: any }, toPath: (string | Location) & Location) {
      const options: any = { credentials: 'same-origin' }
      if (state.lastModified) options.headers = { 'If-Modified-Since': state.lastModified }

      const response = await fetch(`/version?t=${new Date().toISOString()}`, options)
      const responseText = await response.text()

      if (!state.version) {
        const lastVersion = localStorage.getItem('version')
        localStorage.setItem('version', responseText)

        if (lastVersion && lastVersion !== responseText) window.location = toPath

        commit('version', { version: responseText, lastModified: response.headers.get('last-modified') })
      } else if (response.status === 200) {
        if (responseText && responseText !== state.version) window.location = toPath
      }
    },

    async fetchDataTypes ({ state, commit }: { state: any; commit: any }) {
      if (state.dataTypes.length) return
      commit('dataTypes', await fetchDataTypes())
    },

    async fetchApprovals ({ state, commit }: { state: any; commit: any }) {
      const approvals = await fetchApprovals()
      commit('hasApprovals', approvals.length > 0)
    },
  },

  modules: {},
})
